// AuthProvider.js
import {
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  getAdditionalUserInfo
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "../../firebase/config";
import { AuthContext } from "./AuthContext";
import { createFirestoreUser, getUser } from "../../firebase/firebaseFunctions";
import dayjs from "dayjs";
import { serverTimestamp, Timestamp } from "firebase/firestore";


const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);


  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const loginUser = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = async () => {
    setLoading(true);
    setUser(null);
    return await signOut(auth);
  };

  const authWithGoolge = async () => {
    const provider = new GoogleAuthProvider();
    const userCredentials = await signInWithPopup(auth, provider);
    const additionalInfo = getAdditionalUserInfo(userCredentials);
    return { ...userCredentials, isNewUser: additionalInfo.isNewUser }
  }

  const handleForgetPassword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const createNewUserObject = async ({ uid, email, fullName, refUserId, freeModeDayCount, codeReferredWith }) => {
    await createFirestoreUser({
      userId: uid,
      email: email,
      displayName: fullName,
      refCode: '',
      refUserId,
      type: 'user',
      codeReferredWith,
      createdAt: serverTimestamp(),
      freeModeEnds: Timestamp.fromDate(dayjs().add(freeModeDayCount || 14, 'day').toDate()),
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const user = await getUser({ userId: currentUser.uid })
        if (user) {
          const userOnFreeMode = user?.freeModeEnds ? dayjs().isSameOrBefore(dayjs(user?.freeModeEnds?.toDate())) : false;
          setUser({
            ...user,
            userOnFreeMode,
            emailVerified: currentUser.emailVerified,
            uid: currentUser.uid
          });
        } else {
          setUser(currentUser);
        }
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const authValue = {
    user,
    setUser,
    createUser,
    loginUser,
    authWithGoolge,
    handleForgetPassword,
    createNewUserObject,
    logOut,
    loading,
  };

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => {
  const allContextValues = useContext(AuthContext);
  return {
    ...allContextValues,
  };
};

export default AuthProvider;