import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import ButtonComponent from '../Button/button';
import './styles.scss';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import { AFFILIATECODE, getDocuments, getUser } from '../../firebase/firebaseFunctions';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';
import { useState } from 'react';
import { ref } from 'firebase/storage';

const GoogleAuthButton = ({ text, refCode }) => {
	const { authWithGoolge, createNewUserObject } = useAuthContext();
	const { showErrorMessage } = useNotificationContext();
	const [authLoading, setAuthLoading] = useState(false);

	const initiateAuth = async () => {
		setAuthLoading(true);
		try {
			const userCredentials = await authWithGoolge();
			if (!userCredentials.isNewUser) return;
			let refUserId = '';
			let freeModeDayCount = null;
			if (refCode) {
				const user = await getUser({ refCode });
				const [reffCodeDetails] = await getDocuments(AFFILIATECODE, { id: refCode });
				refUserId = user?.userId || '';
				if (reffCodeDetails) freeModeDayCount = reffCodeDetails?.freeModeDayCount || null;
			}
			await createNewUserObject({
				uid: userCredentials.user.uid,
				email: userCredentials.user.email,
				fullName: userCredentials.user.displayName,
				refUserId,
				freeModeDayCount,
				codeReferredWith: refUserId ? refCode : '',
			});
			localStorage.removeItem('refCode');
		} catch (error) {
			showErrorMessage('An error occurred');
		} finally {
			setAuthLoading(false);
		}
	};
	return <ButtonComponent loading={authLoading} className="o-auth_btn" buttonText={text} buttonWidth="15rem" onClick={initiateAuth} icon={faGoogle} />;
};

export default GoogleAuthButton;
