import React, { useEffect } from 'react';
import './styles.scss';
import { useFormik } from 'formik';
import ButtonComponent from '../../Components/Button/button';
import * as Yup from 'yup';
import CustomInput from '../../Components/forms/Input';
import PasswordInput from '../../Components/forms/passwordInput';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';
import { sendEmailVerification } from 'firebase/auth';
import GoogleAuthButton from '../../Components/googleAuthButton';
import { AFFILIATECODE, getDocuments, getUser } from '../../firebase/firebaseFunctions';

const SignUpPage = () => {
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const refCodeFromParam = searchParams.get('ref') || localStorage.getItem('refCode');
	const paymentModalOpen = searchParams.get('pm');
	const { createUser, createNewUserObject, logOut, user, loading: userDataLoading } = useAuthContext();
	const { showErrorMessage, showInfoMessage } = useNotificationContext();
	const navigate = useNavigate();

	const onSubmit = async (submitdata, actions) => {
		if (actions.isSubmitting) return;
		const { email, password, fullName, refCode } = submitdata;
		try {
			const userCredentials = await createUser(email, password);
			let refUserId = '';
			let freeModeDayCount = null;
			if (refCode) {
				const user = await getUser({ refCode });
				const [reffCodeDetails] = await getDocuments(AFFILIATECODE, { id: refCode });
				refUserId = user?.userId || '';
				if (reffCodeDetails) freeModeDayCount = reffCodeDetails?.freeModeDayCount || null;
			}
			await createNewUserObject({
				uid: userCredentials.user.uid,
				email: userCredentials.user.email,
				fullName,
				refUserId,
				freeModeDayCount,
				codeReferredWith: refUserId ? refCode : '',
			});
			localStorage.removeItem('refCode');
			await sendEmailVerification(userCredentials.user);
			await logOut();
			showInfoMessage('Sign-up successfull. Check your email for verification');
			setTimeout(() => {
				if (paymentModalOpen) {
					navigate('/signin?pm=true');
				} else {
					navigate('/signin');
				}
			}, 4700);
		} catch (err) {
			if (err.code === 'auth/email-already-in-use') {
				showErrorMessage('Account already exist');
			} else {
				showErrorMessage('An error occurred');
			}
		} finally {
			actions.setSubmitting(false);
		}
	};

	const { handleChange, handleBlur, handleSubmit, values, errors, touched, isSubmitting, setFieldValue } = useFormik({
		initialValues: {
			fullName: '',
			email: '',
			password: '',
			refCode: '',
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			fullName: Yup.string().required().min(3).label('Name'),
			email: Yup.string().required().email().label('Email'),
			password: Yup.string().required().min(6).label('Password'),
		}),
		onSubmit,
	});

	useEffect(() => {
		if (!userDataLoading && user?.emailVerified) {
			navigate({ pathname: '/app/overview', search: location.search });
		}
		// eslint-disable-next-line
	}, [user, userDataLoading]);

	useEffect(() => {
		if (refCodeFromParam) {
			setFieldValue('refCode', refCodeFromParam);
			localStorage.setItem('refCode', refCodeFromParam);
		}
		// eslint-disable-next-line
	}, [refCodeFromParam]);
	if (userDataLoading && !user) {
		return <div className="auth-page"></div>;
	}

	return (
		<div className="auth-page">
			<div className="inner-container">
				<p className="heading">Sign up for a Free Account</p>
				<div className="o-auth_btns_container">
					<GoogleAuthButton text="Sign up with Google" refCode={values?.refCode} />
				</div>
				<div className="or-flex">
					<hr className="flex-line" />
					<p className="flex-text">Or</p>
					<hr className="flex-line" />
				</div>

				<form onSubmit={handleSubmit}>
					<div className="forms_input">
						<CustomInput
							label="Full Name"
							placeholder="Enter full name"
							name="full name"
							type="text"
							onChange={handleChange('fullName')}
							errorVisible={touched['fullName']}
							errorMessage={errors['fullName']}
							onBlur={handleBlur('fullName')}
							value={values.fullName}
						/>
						<CustomInput
							label="Email address"
							placeholder="Enter email address"
							name="email"
							type="email"
							onChange={handleChange('email')}
							errorVisible={touched['email']}
							errorMessage={errors['email']}
							onBlur={handleBlur('email')}
							value={values.email}
						/>

						<PasswordInput
							label="Password"
							placeholder="Enter password"
							onChange={handleChange('password')}
							errorVisible={touched['password']}
							errorMessage={errors['password']}
							onBlur={handleBlur('password')}
							value={values.password}
						/>

						<CustomInput
							label="Referral code"
							placeholder="Enter referral code"
							name="refcode"
							type="text"
							onChange={handleChange('refCode')}
							errorVisible={touched['refCode']}
							errorMessage={errors['refCode']}
							onBlur={handleBlur('refCode')}
							value={values.refCode}
						/>
					</div>
					<ButtonComponent type="submit" loading={isSubmitting} className="submit_btn" buttonText="Create Account" buttonWidth="15rem" bgcolor="#8CFBDE" />
				</form>

				<p className="alternative-option">
					Already have an account?{' '}
					<NavLink to={{ pathname: '/signin', search: location.search }} className="alternate-text">
						{' '}
						Login
					</NavLink>
				</p>
			</div>
		</div>
	);
};

export default SignUpPage;
